import iterumFinancial from './iterumFinancial.png';
import techy from './techy.png';
import arCapitalGroup from './arCapitalGroup.png';

const WorkExperience = () => {
    const positions = [
        {
            "title": "Software Developer",
            "company": "Iterum Financial",
            "type": "Full-time",
            "start": "Jan 2024",
            "end": "Present",
            "responsibilities": [
                "Designing, developing, and maintaining websites for various companies, ensuring they are responsive, user-friendly, and optimized for search engines.",
                "Collaborating with clients to understand their requirements and translate them into deliverables.",
                "Utilizing HTML, CSS, JavaScript, and frameworks like ReactJS and Bootstrap to create interactive websites.",
                "Setting up and managing LAMP stacks (Linux, Apache, MySQL, PHP) on VPS (Virtual Private Server) environments.",
                "Integrating third-party APIs and services to enhance website functionality.",
                "Providing ongoing website maintenance, updates, and support."
            ],
            "logo": iterumFinancial,
        },
        {
            "title": "Store Manager",
            "company": "Techy Pines",
            "type": "Full-time",
            "start": "May 2023",
            "end": "Dec 2023",
            "location": "Pembroke Pines, Florida, United States",
            "responsibilities": [
                "Contributed to an average increase from approximately $17,000 to $27,000 in monthly sales.",
                "Fostered an addition of over 100 reviews during tenure, reflecting customer satisfaction and service quality.",
                "Streamlined repair processes, enhancing efficiency and reducing operational downtime.",
                "Ensure compliance with franchise policies.",
                "Developed and executed marketing initiatives to bolster brand awareness and attract new clientele."
            ],
            'logo': techy,
        },
        {
            "title": "Technology Coordinator",
            "company": "AR Capital Group",
            "type": "Full-time",
            "start": "Jul 2022",
            "end": "Mar 2023",
            "location": "Hollywood, Florida, United States",
            "responsibilities": [
                "Formulate and execute a comprehensive IT strategy in alignment with organizational objectives, preparing for future technology trends.",
                "Lead the development and enforcement of cybersecurity policies.",
                "Conduct security audits, and managed risk assessments to protect IT assets.",
                "Organize educational programs on the latest technology tools, cybersecurity, and IT policies, boosting staff technical competencies.",
                "Manage IT projects from start to finish, ensuring they met deadlines, budgets, and specifications.",
                "Develop, review, and update IT policies and procedures to ensure compliance with evolving laws.",
                "Negotiate contracts with IT service providers, managing relationships to secure favorable terms and quality service delivery.",
                "Oversee the organization's IT infrastructure and cloud services."
            ],
            'logo': arCapitalGroup,
        }
    ];
    return (
        <div id='workExperience' style={{ minHeight: '80vh' }}>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", gap: '50px' }}>

                {
                    positions.map((position, index) => {
                        return (
                            <div id={index} style={{ backgroundColor: '#AAA6C9', width: '500px', borderRadius: '10px', padding: '25px', margin: '10px' }}>
                                <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        {position.start}
                                    </div>

                                    <div>
                                        {position.end}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <div>
                                        <div style={{ marginTop: '10px', textAlign: "center", fontSize: '30px' }}>{position.title}</div>
                                        <div style={{ marginBottom: '10px', textAlign: "center", fontSize: '20px' }}>{position.company}</div>

                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>

                                    <img src={position.logo} style={{ objectFit: 'cover', width: '100%', maxWidth: '300px' }} alt="" />

                                </div>
                                <div style={{ backgroundColor: '#AAA6C9', }} >

                                    {
                                        position.responsibilities.splice(0, 3).map((responsibility, index2) => {
                                            return (
                                                <div style={{ textAlign: "center", padding: '5px', borderBottom: index2 < 2 ? '2px solid #8581B1' : '' }}>{responsibility}</div>
                                            )

                                        })
                                    }
                                </div>

                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    )
}

export default WorkExperience;