import { useEffect, useState } from "react";
import { CiHome, CiGrid41, CiCircleList, CiUser, CiSaveDown2 } from "react-icons/ci";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { MdOutlineWorkOutline } from "react-icons/md";
import { LuMessageCircle, LuPhone } from "react-icons/lu";

const Nav = () => {
    const [activeSection, setActiveSection] = useState('home');

    const navItems = [
        { title: 'Home', docElId: 'home', icon: <CiHome size={'50px'} /> },
        { title: 'About Me', docElId: 'aboutMe', icon: <CiUser size={'50px'} /> },
        { title: 'Projects', docElId: 'projects', icon: <CiGrid41 size={'50px'} /> },
        { title: 'Work Experience', docElId: 'workExperience', icon: <CiCircleList size={'50px'} /> }
    ];

    function scrollTo(elementId) {
        const element = document.getElementById(elementId);
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const getCurrentSection = () => {
        let mostVisibleSectionId = navItems[0].docElId;
        let maxVisibleHeight = 0;

        navItems.forEach(({ docElId }) => {
            const section = document.getElementById(docElId);
            if (!section) return;

            const { top, bottom } = section.getBoundingClientRect();
            const visibleHeight = Math.min(window.innerHeight, bottom) - Math.max(0, top);

            if (visibleHeight > maxVisibleHeight) {
                maxVisibleHeight = visibleHeight;
                mostVisibleSectionId = docElId;
                setActiveSection(docElId);
                console.log(docElId);
            }
        });

        setActiveSection(mostVisibleSectionId); // Update the active section state
    };

    useEffect(() => {
        const handleScroll = () => {
            getCurrentSection(); // Call this function on scroll
        };
        window.addEventListener('scroll', handleScroll); // Add the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScroll); // Remove the scroll listener on cleanup
        };
    }, []);

    return (
        <div style={{ backgroundColor: 'black', position: 'fixed', width: '100px', display: "flex", justifyContent: "center", alignItems: "center", height: '100vh', flexWrap: "wrap" }}>
            <div style={{ border: 'solid 2px #928DB9', borderRadius: '10px', display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", justifyItems: "center", gap: '10px' }}>
                <a href="mailto:8169203959">
                    <FiMail size={'40px'} style={{ color: '#CFCDDF' }} />
                </a>
                <a href="sms:8169203959">
                    <LuMessageCircle size={'40px'} style={{ color: '#CFCDDF' }} />
                </a>
                <a href="tel:8169203959">
                    <LuPhone size={'40px'} style={{ color: '#CFCDDF' }} />
                </a>
            </div>
            {navItems.map((navItem, index) => (
                <a key={index} href="#" onClick={() => scrollTo(navItem.docElId)} >
                    <div style={{ textAlign: "center", padding: '10px', fontWeight: "bold", color: 'white', backgroundColor: navItem.docElId === activeSection ? '#8581B1' : 'black' }}>
                        {navItem.icon}
                        {navItem.title}
                    </div>
                </a>
            ))}
            <div style={{ border: 'solid 2px #928DB9', borderRadius: '10px', display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", justifyItems: "center", gap: '10px' }}>
                <div style={{ color: 'white', textAlign: "center", fontWeight: "bold" }}>
                    <CiSaveDown2 size={'30px'} style={{ color: '#fff' }} />
                    Download <br /> Resume
                </div>
                <a href="https://github.com/holmesjeremiah">
                    <FaGithub size={'40px'} style={{ color: '#CFCDDF' }} />
                </a>
                <a href="https://www.linkedin.com/in/jeremiah-holmes/">
                    <FaLinkedinIn size={'40px'} style={{ color: '#CFCDDF' }} />
                </a>
            </div>
        </div>
    );
}

export default Nav;
