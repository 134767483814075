
import './App.css'
import Nav from './Components/Nav';
import WorkExperience from './Components/WorkExperience/WorkExperience';


const HomePage = () => {
    return (

        <div style={{ display: 'flex', }}>
            <Nav />
            <div style={{ width: '100px', backgroundColor: 'black' }}>

            </div>
            <div style={{ width: 'calc(100vw - 100px)', }}>

                <div id='home' style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
                    <img style={{ width: '5em', }} src="/logo.png" alt="" />
                    <div style={{}}>
                        <div style={{ color: '#928CBA', fontSize: window.innerWidth < 1100 ? '20px' : '40px' }}>Hello my name is</div>
                        <div style={{ color: 'white', fontSize: window.innerWidth < 1100 ? '40px' : '75px' }}>Jeremiah H.</div>
                        <div style={{ color: 'white', color: '#928CBA', fontSize: window.innerWidth < 1100 ? '15px' : '25px' }}>I am a software developer.</div>
                    </div>

                </div>
                <WorkExperience />
            </div >
        </div >

    )
}

export default HomePage;